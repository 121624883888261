import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import simplyVelvet from '../images/simply-velvet.png'
import musicForVelvet from '../images/music-for-velvet.png'
import velvet from '../images/velvet.png'
import testimony from '../images/testimony.jpg'
import bowlinggreen from '../images/bowlinggreen.jpg'
import perspectives from '../images/perspectives.png'
import scarpe from '../images/scarpe.jpg'
import stilettoDoc from '../images/stiletto1.jpg'
import lonnie from '../images/lonnie.png'
import wolf from '../images/wolf.png'

import '../styles/common-styles.css'
import '../styles/music-styles.css'
import '../styles/layout-styles.css'

const MusicPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Discography</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumGridWrapper'>
          <div className='albumGridRow'>
            <Link to='/albums/lonnies-lament'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={lonnie} alt='' />
                <span>Lonnie's Lament</span>
                <span>2021</span>
              </div>
            </Link>
            <Link to='/albums/scarpe'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={scarpe} alt='' />
                <span>Scarpe!</span>
                <span>2020</span>
              </div>
            </Link>
            <Link to='/albums/testimony'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={testimony} alt='' />
                <span>Testimony</span>
                <span>2017</span>
              </div>
            </Link>
            <Link to='/albums/stiletto-severinsen'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={stilettoDoc} alt='' />
                <span>Stiletto Brass Quintet with Doc Severinsen</span>
                <span>2013</span>
              </div>
            </Link>
          </div>
          <div className='albumGridRow'>
            <Link to='/albums/simply-velvet'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={simplyVelvet} alt='' />
                <span>Simply Velvet</span>
                <span>2011</span>
              </div>
            </Link>
            <Link to='/albums/perspectives'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={perspectives} alt='' />
                <span>Perspectives in Rhythm</span>
                <span>2005</span>
              </div>
            </Link>
            <Link to='/albums/bowling-green'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={bowlinggreen} alt='' />
                <span>New music from Bowling Green</span>
                <span>2004</span>
              </div>
            </Link>
            <Link to='/albums/music-for-velvet'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={musicForVelvet} alt='' />
                <span>Music for Velvet</span>
                <span>2001</span>
              </div>
            </Link>
          </div>
          <div className='albumGridRow'>
            <Link to='/albums/heart-of-a-wolf'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={wolf} alt='' />
                <span>Heart of a Wolf</span>
                <span>2000</span>
              </div>
            </Link>
            <Link to='/albums/velvet'>
              <div className='albumGridItem'>
                <img className='albumGridImage' src={velvet} alt='' />
                <span>Velvet</span>
                <span>1998</span>
              </div>
            </Link>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumGridWrapper'>
          <Link to='/albums/lonnies-lament'>
            <div className='mobileAlbumGridItem'>
              <img className='mobileAlbumGridImage' src={lonnie} />
              <span>Lonnie's Lament</span>
              <span>2021</span>
            </div>
          </Link>
          <Link to='/albums/scarpe'>
            <div className='mobileAlbumGridItem'>
              <img className='mobileAlbumGridImage' src={scarpe} alt='' />
              <span>Scarpe!</span>
              <span>2020</span>
            </div>
          </Link>
          <Link to='/albums/testimony'>
            <div className='mobileAlbumGridItem'>
              <img className='mobileAlbumGridImage' src={testimony} alt='' />
              <span>Testimony</span>
              <span>2017</span>
            </div>
          </Link>
          <Link to='/albums/stiletto-severinsen'>
            <div className='mobileAlbumGridItem'>
              <img className='mobileAlbumGridImage' src={stilettoDoc} alt='' />
              <span>Stiletto Brass Quintet with Doc Severinsen</span>
              <span>2013</span>
            </div>
          </Link>
          <Link to='/albums/simply-velvet'>
            <div className='mobileAlbumGridItem'>
              <img className='mobileAlbumGridImage' src={simplyVelvet} alt='' />
              <span>Simply Velvet</span>
              <span>2011</span>
            </div>
          </Link>
          <Link to='/albums/perspectives'>
            <div className='mobileAlbumGridItem'>
              <img className='mobileAlbumGridImage' src={perspectives} alt='' />
              <span>Perspectives in Rhythm</span>
              <span>2005</span>
            </div>
          </Link>
          <Link to='/albums/bowling-green'>
            <div className='mobileAlbumGridItem'>
              <img className='mobileAlbumGridImage' src={bowlinggreen} alt='' />
              <span>New music from Bowling Green</span>
              <span>2004</span>
            </div>
          </Link>
          <Link to='/albums/music-for-velvet'>
            <div className='mobileAlbumGridItem'>
              <img
                className='mobileAlbumGridImage'
                src={musicForVelvet}
                alt=''
              />
              <span>Music for Velvet</span>
              <span>2001</span>
            </div>
          </Link>
          <Link to='/albums/heart-of-a-wolf'>
            <div className='mobileAlbumGridItem'>
              <img className='mobileAlbumGridImage' src={wolf} alt='' />
              <span>Heart of a Wolf</span>
              <span>2000</span>
            </div>
          </Link>
          <Link to='/albums/velvet'>
            <div className='mobileAlbumGridItem'>
              <img className='mobileAlbumGridImage' src={velvet} alt='' />
              <span>Velvet</span>
              <span>1998</span>
            </div>
          </Link>
        </div>
      )}
    </Layout>
  )
}

export default MusicPage
